import * as React from "react"
import { Link } from "gatsby"

import * as headerStyles from "./header.module.css"

const Header = () => {
  const navLinks = [
        {
            link: "/",
            text: "Home"
        },
        {
            link: "/posts",
            text: "Posts"
        },
        {
            link: "/categories",
            text: "Categories"
        },
        {
            link: "/tags",
            text: "Tags"
        }
  ]

  return (
    <header
      className={headerStyles.navBar}
    >
      <ul className={headerStyles.navLinks}>
        {navLinks
          .map((navLink) => {
            return (
              <li key={navLink}>
                <Link
                  to={navLink.link}
                >
                {navLink.text}
                </Link>
              </li>
            )
          })
        }
      </ul>
    </header>
  )
}

export default Header
