/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query{
        site {
          siteMetadata {
            author
            description
            title
          }
        }
      }
    `
  )

  const fixedDescription = description.replace(title + " ", "")
  const metaDescription = fixedDescription || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const location = useLocation()

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `fb:app_id`,
            content: ``,
          },
          {
            property: `og:image`,
            content: `../images/family-drawing.png`,
          },
          {
            property: `og:site_name`,
            content: `mattdood.com`,
          },
          {
            property: `og:url`,
            content: `${location.href}`,
          },
          {
            property: `og:locale`,
            content: `en_US`,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata?.author || ``,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      >
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous" />
        <link rel="alternate" href={location.href} hreflang="en-us" />
        <link rel="alternate" href={location.href} hreflang="x-default" />
        <link rel="canonical" href={location.href} />
        <script defer data-domain="mattdood.com" src="https://plausible.io/js/plausible.js"></script>
      </Helmet>
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
